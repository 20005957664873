@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding: env(safe-area-inset);
}
body {
  background-color: #ffffff;
  font-family: "Poppins";
  overflow-x: hidden;
}
body * {
  font-family: "Poppins";
}
div[disabled]
{
  pointer-events: none;
  opacity: 0.7;
}
button:disabled,
button[disabled]{
  cursor: not-allowed;
}

/* div#chat-widget-container {
  bottom: 65px !important;
}  */

#__next {
  height: 100%;
}

.cursor_pointer {
  cursor: pointer;
}

.ant-select-dropdown {
  border-radius: 14px !important;
}

.ant-spin-spinning {
  
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  background-color: transparent !important;

}
.react-player__preview {
  background-position: top !important;
  border-radius: 10px;
}
/* Buttons-Hover */
.green-btn:hover,
.green-btn:focus {
  background-color: #12a45e !important;
}
.primary-btn:hover,
.primary-btn:focus {
  background-color: #21bf732e !important;
}
.white-btn:hover,
.white-btn:focus {
  background-color: #f5f5f5 !important;
}
.grey-btn:hover,
.grey-btn:focus {
  background-color: #30396057 !important;
}
.red-btn:hover,
.red-btn:focus {
  background-color: #9f1b3a !important;
}
.disabled-btn {
  background-color: #21bf730f !important;
}
.yellow-btn:hover,
.yellow-btn:focus {
  background-color: #dea403 !important;
}
.MuiButton-root.Mui-disabled {
  color: unset !important;
}
.MuiButton-root.add_btn {
  color: green !important;
}
.add_company_action .ok_btn.MuiButton-root.Mui-disabled {
  background-color: #21bf73;
}

/* location */
.pac-container.pac-logo::after {
  display: none !important;
}

/* error msg */
.error {
  color: #fb5050 !important;
  font: normal 12px / 14px "Poppins";
}

/* label */
.label.bl-label {
  margin-bottom: 6px !important;
  display: inline-block;
  margin-top: 0;
}

/* custom scrollbar */
.custom_scrollbar > div:nth-child(3) {
  right: 5px !important;
  width: 7px !important;
}
.custom_scrollbar > div:nth-child(3) > div {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.custom_scrollbar > div:nth-child(3) > div:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

/* pagination */
.mat-pagination {
  display: flex;
  justify-content: flex-end;
}
.mat-pagination ul li > button {
  font: 500 12px/14px "Roboto";
  color: #424242;
}
.mat-pagination .MuiSvgIcon-root {
  color: #424242 !important;
}
.mat-pagination ul li > button.MuiPaginationItem-page:hover {
  background-color: rgba(47, 61, 123, 0.2);
}
.mat-pagination ul li > button.MuiPaginationItem-page.Mui-selected {
  background-color: #2f3d7b;
  color: #ffffff;
}

.mt-6 {
  margin-top: 6rem !important;
}

/* error */
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
}

/* height increase in modal */
.auto_incress .MuiDialog-paperWidthMd {
  min-height: 350px;
}
.pro-sidebar{
  z-index: 99 !important;
}

/* Tooltip */
.tooltip-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
}

/* Table Cell Cursor */
.tablecell_container td {
  cursor: pointer;
}

input[type="file"] {
  color: transparent;
}
input[type="file"]:hover {
  color: transparent;
}

/* Common Css */
.img_btn.propsal_share > img {
  width: 45px;
  height: 44px;
}
.img_btn,
.img_btn:hover,
.img_btn:focus {
  border: none;
  outline: none;
  background-color: transparent !important;
  box-shadow: none !important;
  line-height: 0;
  padding: 0;
}

.fixed-nav {
  margin-top: 110px;
}
.border-less {
  border: none !important;
}
/* Common Css End */

.ant-spin-spinning {
  position: fixed !important;
  display: flex !important;
  opacity: 1 !important;
  top: 0;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999;
}
.ant-spin-dot-item {
  background-color: #29a6ed !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
  box-shadow: none !important;
  border-color: none !important;
}

.ant-popover-inner {
  border-radius: 12px;
}

/* Settings Page */
.settings_page_header {
  position: relative;
  padding: 62px 72px;
  margin-bottom: 27px;
  overflow: hidden;
}
.settings_page_header > *:not(.settings_page_header_bg) {
  position: relative;
  z-index: 2;
}
.settings_page_header_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("/assets/images/settings_bg.png");
  background-size: cover;
  border-radius: 0 0 50% 50%/ 0 0 100% 100%;
  transform: scaleX(1.7);
  z-index: 1;
}
.settings_page_heading {
  font: 600 28px/34px "Poppins";
  color: #c3c3c3;
  margin: 0 auto 16px;
  max-width: 601px;
}
.settings_page_para {
  font: normal 42px/60px "Poppins";
  color: #ffffff;
  max-width: 601px;
  margin: 0 auto;
}
/* Settings Page End */

/* Settings Body */
.settings_page_body {
  padding: 0 50px;
  max-width: 1178px;
  margin: 0 auto;
}
.input_section_label {
  font: normal 18px/24px "Poppins";
  color: #5b5b5b;
  margin-bottom: 8px;
}
.input_field-settings {
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  margin-bottom: 30px;
  padding: 0 21px;
}
.input_field input,
.input_field input:focus {
  font: normal 18px/24px "Poppins";
  color: #777777;
}
.settings_page input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
}
.settings_primary_btn {
  font: normal 18px/24px "Poppins" !important;
  color: #ffffff !important;
  border-radius: 7px !important;
  padding: 0 24px !important;
  height: 52px !important;
  background-color: #de5e33 !important;
  border: none !important;
  box-shadow: none !important;
}
.settings_primary_btn span {
  font: normal 18px/24px "Poppins" !important;
  color: #ffffff !important;
}
.settings_primary_btn::after {
  content: none !important;
}
.settings_cancel_btn {
  font: normal 18px/24px "Poppins";
  color: #de5e33;
  background: transparent;
  border: none;
}
.settings_left_btn {
  margin-right: 48px;
}
.input_field-settings-upload {
  border: 1px dashed #dfdfdf;
  padding: 32px;
  height: auto;
  position: relative;
  overflow: hidden;
}
.input_field-settings-upload > img {
  margin-bottom: 4px;
}
.input_field-settings-upload input {
  position: absolute;
  width: 120%;
  height: 100%;
  top: 0;
  left: -120px;
  opacity: 0;
  cursor: pointer;
}
.input_field-settings-upload_text {
  font: normal 18px/24px "Poppins";
  color: #5b5b5b;
}
.input_field-settings-upload_text span {
  font: normal 18px/24px "Poppins";
  color: #de5e33;
}
.input_field-settings-uploaded {
  background-color: #d8fcec;
  border: 1px solid transparent;
}
.input_field-settings-uploaded_text {
  font: normal 18px/24px "Poppins";
  color: #21bf73;
  margin-bottom: 0;
}
.input_field-settings-uploaded_text img {
  margin-right: 9px;
}
/* Settings Body End */

/* New Password Start */
.invite_primary_btn {
  font: normal 18px/24px "Poppins" !important;
  color: #ffffff !important;
  border-radius: 7px !important;
  padding: 0 24px !important;
  height: 52px !important;
  background-color: #29a6ed !important;
  border: none !important;
  box-shadow: none !important;
}
.invite_primary_btn span {
  font: normal 18px/24px "Poppins" !important;
  color: #ffffff !important;
}
.invite_primary_btn::after {
  content: none !important;
}
.invite_cancel_btn {
  font: normal 18px/24px "Poppins";
  color: #29a6ed;
  background: transparent;
  border: none;
}
.invite_left_btn {
  margin-right: 48px;
}
.invite_page_para {
  font: normal 42px/60px "Poppins";
  color: #000000;
  max-width: 601px;
  margin: 0 auto;
}
.invite_page input {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0;
}
/* New Password End */

/* Verification Page */
.verification_page {
  background-color: #f1f1f1;
  padding: 64px 76px;
  min-height: 100vh;
}
.verification_section {
  background-image: url("/assets/images/varify_bg.svg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  border-radius: 11px;
  background-color: #ffffff;
  padding: 72px 72px 32px;
  width: 100%;
  max-width: 740px;
}
.verification_section > figure {
  margin-bottom: 24px;
}
.verification_heading {
  font: normal 32px/40px "Poppins";
  color: #211828;
  margin-bottom: 5px;
}
.verification_para {
  font: normal 18px/24px "Poppins";
  color: #9d9a9e;
  margin-bottom: 22px;
}
/* Verification Page */

/* Band Detail Page */
.band_detail_nav {
  position: relative;
  background-color: #1a2632;
  margin-bottom: -1px;
}
.band_detail_nav div {
  justify-content: center;
}
.band_detail_nav .sticky-inner {
  white-space: nowrap;
  overflow-x: auto;
}
.band_detail_nav_sticky .sticky-inner {
  background-color: #1a2632;
  width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 9 !important;
}

.scroll_down {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}
.scroll_down_object {
  width: 20px;
  height: 30px;
  border: 1px solid #645767;
  position: relative;
  margin: 0 auto 4px;
  border-radius: 10px;
}
.scroll_down_object span {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #ffffff;
  left: 50%;
  top: 5px;
  transform: translate(-50%, 0);
  animation: MoveUpDown 1.5s linear infinite;
  position: absolute;
}
@keyframes MoveUpDown {
  0%,
  100% {
    top: 5px;
  }
  50% {
    top: 18px;
  }
}
.scroll_down_title {
  font: 500 14px/18px "Poppins";
  color: #2f2233;
  margin-bottom: 0;
}
.scroll_down_text {
  font: normal 12px/16px "Poppins";
  color: #786e82;
  margin-bottom: 0;
}

.sr-only {
  display: none;
}

@media (max-width: 767px) {
  .band_detail_nav_sticky .sticky-inner {
    top: 165px;
    padding: 0px 0px;
  }
}

.band_detail_nav_item {
  margin: 0 42px;
  /* border-bottom: 5px solid #29a6ed; */
}
.band_detail_nav_item p {
  text-align: center;
  font: 15px/21px Poppins;
  color: #e2d8ee;
  padding: 15px 0;
  user-select: none;
  cursor: pointer;
}
.band_detail_nav_item.active p {
  font: 600 15px/21px Poppins;
  color: #29a6ed;
}
.band_detail_nav_item.active .active_nav_item {
  height: 5px;
  background-color: #29a6ed;
  border-radius: 3px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}
/* Band Detail Page */

/* Password Strength */
.password_section_login-settings .password_section_login {
  min-width: 410px;
}
.password_rule_heading {
  font: normal 12px/14px "Poppins";
  color: #ffffff;
  margin-bottom: 10px;
}
.password_section_login .password_rule_heading {
  color: #221f1f;
}
.password_rules {
  list-style: none;
  font: normal 12px/14px "Poppins";
  color: #929292;
  margin-bottom: 18px;
  padding: 0;
}
.password_section_login .password_rules {
  list-style: none;
  margin-bottom: 18px;
}
.password_rules li {
  margin-bottom: 12px;
  font: normal 14px/20px "Poppins";
  color: rgba(119, 112, 112, 60%);
}
.password_section_login .password_rules li {
  margin-bottom: 3px;
}
.password_rules li:last-child {
  margin-bottom: 0;
}
.password_rule_type {
  font: normal 12px/14px "Poppins";
  margin-bottom: 10px;
}
.password_rule_type span {
  margin-right: 7px;
}
.password_good .password_rule_type {
  color: #50c31e;
}
.password_average .password_rule_type {
  color: #ffa41a;
}
.password_poor .password_rule_type {
  color: #ef3102;
}
progress.password_strength {
  border-radius: 0;
  width: 100%;
  height: 13px;
  margin-left: 0;
  background-color: rgba(197, 197, 197, 19%);
  /* box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); */
}
progress.password_strength::-webkit-progress-bar {
  background-color: rgba(243, 242, 242, 0.19);
}
.password_good progress.password_strength::-webkit-progress-value {
  background-image: linear-gradient(270deg, #50c31e, #ffa41a, #ef3102);
}
.password_average progress.password_strength::-webkit-progress-value {
  background-image: linear-gradient(270deg, #ffa41a, #ef3102);
}
.password_poor progress.password_strength::-webkit-progress-value {
  background-image: linear-gradient(270deg, #f7680d, #ef3102);
}
.password_rule_sug {
  font: normal 11px/14px "Poppins";
  color: #929292;
  margin-bottom: 5px;
}
.password_section_login .password_rule_sug {
  font: normal 12px/14px "Poppins";
  color: #747272;
}
/* Password Strength End */

/* About Us Start */
.about_us_container {
  padding: 55px 80px;
  position: relative;
  overflow: hidden;
  background: url("/assets/images/header_rot_bg.png") 0 0 no-repeat;
  background-position: bottom;
}
.about_us_container .container-fluid {
  padding: 0 0 64px 0;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.about_us_container .row {
  position: relative;
  z-index: 2;
}
.about_us_container_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1a2632;
  border-radius: 0 0 50% 50%/ 0 0 100% 100%;
  transform: scaleX(4);
  z-index: 1;
}
.about_us_container_icon1 {
  background: url("/assets/images/about_back_icon1.png") 0 0 no-repeat;
  width: 184px;
  height: 178px;
  z-index: 2;
  position: absolute;
  top: 562px;
  left: 0;
}

.about_us_container_icon2 {
  background: url("/assets/images/about_back_icon2.png") 0 0 no-repeat;
  width: 184px;
  height: 178px;
  z-index: 2;
  position: absolute;
  bottom: 432px;
  right: -100px;
}

.about_us_image_wrapper {
  padding: 32px;
  background-image: linear-gradient(180deg, #323748, #1f2330);
  border-radius: 53px;
  margin-top: 30px;
  max-width: 100%;
  height: fit-content;
  min-height: 350px;
}
.about_us_image {
  border-radius: 27px;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.about_us_detail_wrapper {
  padding-right: 64px;
}
.section_mission,
.section_vision,
.section_about_us {
  margin-bottom: 56px !important;
}
.section_mission {
  border-bottom: 1px solid #333d4c;
  padding-bottom: 35px;
}
.section_vision {
  padding-top: 40px;
}
.about_us_detail_wrapper .section_title,
.section_mission .section_title,
.section_vision .section_title {
  font: 500 28px/36px Poppins;
  letter-spacing: 1.96px;
  color: #ffffff;
  text-transform: uppercase;
}
.about_us_detail_wrapper .section_heading,
.section_mission .section_heading,
.section_vision .section_heading {
  text-align: left;
  font: 600 72px/89px Poppins;
  color: #ffffff;
}
.about_us_container .section_para span p {
  text-align: left;
  font: 16px/30px Poppins;
  color: #b4a9c0;
}
.section_mission .section_title,
.section_vision .section_title {
  color: #29a6ed;
  margin-bottom: 3rem;
}
.section_mission .section_heading,
.section_vision .section_heading {
  font: 600 42px/60px Poppins;
}
.section_mission .section_para span p,
.section_vision .section_para span p {
  margin-top: 80px;
}
.section_para_dash {
  height: 1px;
  width: 70px;
  background-color: #786e82;
}
.about_us_trutedby_container {
  background-image: url("/assets/images/header_bg.webp");
  background-position: center;
}
/* About Us End */

/* Agreement Start */
.agreement_container {
  padding: 55px 112px 64px 144px;
  max-width: 1400px;
  position: relative;
  overflow: hidden;
}
.agreement_container .heading {
  text-align: left;
  font: 600 72px/89px General Sans;
  color: #08151b;
  margin-bottom: 40px;
}
.agreement_container .sub-heading {
  font: 500 28px/36px Poppins;
  letter-spacing: 1.96px;
  color: #29a6ed;
  text-transform: uppercase;
  margin-bottom: 32px;
}
.agreement_content span p {
  text-align: left;
  font: 500 16px/30px Poppins;
  color: #645767;
}
/* Agreement End */

/* Account Start */
#root {
  height: 100%;
}
.account-container {
  height: 100%;
  display: flex;
  position: relative;
}
.account-container .sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-container .sidebar-btn-wrapper .sidebar-btn {
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #adadad;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.account-container .sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 5px;
  font-size: 13px;
}
.account-container .sidebar-btn-wrapper .sidebar-btn span:hover {
  color: #d8d8d8;
}
.account-container .collapsed .sidebar-btn {
  display: inline-block;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 40px;
  padding: 0;
}
.account-container main {
  padding: 14px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  background: url("/assets/images/dashboard_bg.png");
  background-size: cover;
}
.account-container .btn-toggle {
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: #353535;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  display: none;
}
.account-container .sidebar-toggle-btn {
  position: absolute;
  bottom: 120px;
  right: -20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: none;
  object-position: 50% 34%;
}
.account-container .pro-sidebar > .pro-sidebar-inner {
  background: transparent;
}
.account-container .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow-y: initial;
  overflow-x: initial;
}
.pro-icon-wrapper {
  background-color: transparent !important;
}

.pro-menu-item.active .pro-inner-item .pro-item-content .sidebar-menu-text {
  color: white !important;
}
.account-container .pro-sidebar {
  background: url("/assets/images/sidebar_bg.svg");
  background-size: cover;
  border-right: 2px solid #2c2238;
}
.account-heading {
  font: normal 28px/34px "Poppins";
  color: #ffffff;
  padding: 28px 0;
  margin-top: 8px;
}
.account-empty-text {
  font: normal 20px/14px "Poppins";
  color: #ffffff;
  margin-top: 150px;
}
.sidebar-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar-menu-text {
  text-align: left;
  font: 500 14px/20px Poppins;
  color: #848086;
  margin: 0;
}
.profile-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 214px;
  height: 214px;
  background-image: linear-gradient(180deg, #ffffff0a, #ffffff00);
  border-radius: 50%;
}
.sidebar-header figure {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 172px;
  height: 172px;
  background-image: linear-gradient(180deg, #ffffff0a, #ffffff00);
  border-radius: 50%;
  cursor: pointer;
}
.sidebar-header img {
  padding: 5.5px;
  border: 1px solid rgba(255, 255, 255, 0.09);
  border-radius: 50%;
  width: 130px;
  height: 130px;
  object-fit: cover;
}
.sidebar-header-options {
  margin-bottom: 0;
  z-index: 2;
  list-style: none;
}
.sidebar-header-options .sidebar-header-option {
  list-style: none;
  margin-right: 21px;
  cursor: pointer;
}
.dashboard_header_option_menu .ant-dropdown-menu{
  margin: 10px;
  font: normal 10px/16px "Poppins";
  padding: 21px 28px;
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 9px;
  background-color: #ffffff;
  text-align: center;
}
.dashboard_header_option {
  list-style: none;
  cursor: pointer;
}
.dashboard_header_option:last-child {
  margin-right: 0;
}
.dashboard_header_option_badge > sup.ant-badge-dot {
  width: 10px;
  min-width: 10px;
  height: 10px;
  background: #de5e33;
  border-radius: 100%;
  box-shadow: 0 0 0 0px #fff;
  right: 23px;
}
.notification-heading {
  font: normal 18px/28px "Poppins";
  margin-bottom: 0px;
}
.notificationListDropdownContainer .notifi-read-more:hover {
  text-decoration: underline;
  cursor: pointer;
}
.notificationListDropdownContainer .notifi-read-more {
  font: normal 10px/12px "Poppins";
  color: #b9b1bc;
  display: block;
  margin-bottom: 10px;
  width: 100%;
  text-align: right;
  padding: 4px;
}
.notification-single-box-active {
  background: #f9f6f6;
  border-radius: 7px;
  padding: 15px 10px;
  margin-bottom: 10px;
  position: relative;
}
.notification-single-box {
  padding: 15px 10px;
  margin-bottom: 0px;
  position: relative;
}
.notification-single-box:hover {
  background: #f9f6f6;
  border-radius: 7px;
  padding: 15px 10px;
  margin-bottom: 0px;
  position: relative;
  cursor: pointer;
}
.notification-img img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
.notification-title-box {
  width: 100%;
  margin-left: 20px;
  margin-right: 25px;
}
.notificationListDropdownContainer .notifi-title-heading {
  font: normal 12px/16px "Poppins";
  margin-bottom: 0;
  color: #2f2233;
  font-weight: 600;
}
.notificationListDropdownContainer .notifi-description {
  font: normal 10px/16px "Poppins";
  color: #726975;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.notificationListDropdownContainer .notifi-description:hover {
  color: #0052cc;
  text-decoration: underline;
}

.active-dote-notification {
  /*font-size: 60px;
  line-height: 0;
  color: #de5e33;*/
  position: absolute;
  right: 17px;
  top: 0px;
}
.dote-notification {
  font-size: 60px;
  line-height: 0;
  color: #de5e33;
  position: absolute;
  right: 17px;
  top: 0px;
}
.active-dote-notification img {
  border: 2px solid #de5e3354;
  border-radius: 50%;
}
.notificationListDropdownContainer .notifi-datetime:hover {
  text-decoration: none !important;
}
.notificationListDropdownContainer .notifi-datetime {
  font: normal 10px/12px "Poppins";
  color: #b9b1bc;
}
.notificationListDropdownContainer .notification-heading {
  font: normal 18px/28px "Poppins";
  margin-bottom: 0px;
}
.notificationListDropdownContainer::-webkit-scrollbar {
  width: 5px;
}
.notificationListDropdownContainer::-webkit-scrollbar-track {
  background: #f1f1f100;
  border-radius: 50px;
}

.notificationListDropdownContainer::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.438);
  border-radius: 50px;
}
.notificationListDropdownContainer::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.397);
}
.notificationListDropdownContainer {
  scrollbar-color: transparent transparent;
  scrollbar-width: 1px;
}
.collapsed-profile-img {
  width: 32px;
  height: 32px;
  border: 0.2rem solid white;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  background-color: white;
}
.profile-name {
  font: 500 16px/24px "Poppins";
  color: #ffffff;
  margin-bottom: 6px;
  margin-top: -35px;
}
.profile-position {
  font: normal 12px/14px "Poppins";
  color: rgba(255, 255, 255, 0.34);
  margin-bottom: 33px;
}
.profile-detail-section {
  padding: 21px 25px 65.5px 30px;
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 15px;
  max-width: 968px;
}
.account-main-overlay {
  position: absolute;
  top: 0;
  left: 50%;
  margin-bottom: 0;
}
.profile-side-section {
display: block;
margin-left: auto;
margin-right: auto;
}
.profile-side-section > figure {
  position: relative;
  margin: 5px 0 19px;
  display: inline-flex;
}
.profile-side-section > figure > img.avatar {
  width: 137px;
  height: 137px;
  object-fit: cover;
  border-radius: 68px;
  border: 3px solid #ffffff;
}
.profile-image-edit {
  position: absolute;
  width: 34px;
  height: 34px;
  background: #de5e33;
  border-radius: 18px;
  bottom: 6px;
  right: 0;
  overflow: hidden;
}
.profile-image-edit > span {
  width: 34px;
  height: 34px;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.profile-image-edit input {
  opacity: 0;
  position: absolute;
  right: 0;
  height: 100%;
  cursor: pointer;
  border-radius: 18px;
}
.profile-image-edit span span .ant-upload-list {
  display: none;
}
.profile-sidebar-list {
  list-style: none;
  padding: 0;
}
.profile-sidebar-list-item.active {
  color: #ffffff;
}
.profile-sidebar-list-item {
  font: normal 14px/20px "Poppins";
  color: #838188;
  margin-bottom: 23px;
  cursor: pointer;
  margin-right: 20px;
}
.profile-sidebar-list-item figure {
  min-width: 19px;
  margin-right: 12px;
  margin-bottom: 0;
}

.ant-message {
  bottom: 0 !important;
  top: auto !important;
  right: 40px !important;
}
.ant-message-notice {
  text-align: right !important;
  padding: 15px 40px !important;
}
.ant-message-notice-content {
  border-radius: 100px !important;
}
.anticon.anticon-check-circle {
  top: 0px !important;
}
.ant-message-custom-content {
  font: normal normal 600 12px/4px "Poppins" !important;
  display: flex;
  align-items: center;
}
.ant-message-custom-content span {
  font: normal normal 600 12px/4px "Poppins" !important;
}
.ant-message .anticon {
  font-size: 40px !important;
}
/*----------Error Page Start------------------ */
.custome_error_page img{
 width: 100%;
 padding: 50px;
 padding-bottom: 0;
}
.custome_error_page .error_bg{
background-image:url("/assets/images/header_bg.webp");
background-position:center;
background-size:cover;
background-repeat:no-repeat;
height:100vh;
}
.error_img_box{
  max-width: 635px;
  height: auto;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.custome_error_page .text1{
font-weight:bold;
text-align:center;
margin-top: 11px;
font: 600 24px/36px "Poppins";
}
.custome_error_page .text2{
text-align:center;
font: 400 18px/26px "Poppins";
color:#000
}
.custome_error_page .error_button{
border-radius:50px;
background-color:#29a6ed;
cursor:pointer;
border:none;
padding:10px 30px;
margin-top: 10px;
}
.custome_error_page .error_text{
color:#fff;
font-weight:bold;
font: 18px/26px "Poppins";
}
.full_errorpage_box{
  padding-top: 170px;
}
/*----------Error Page End------------------ */
/*----------Toast Message------------------ */
.Toastify__toast-container h6 {
  font: normal normal 600 12px/4px Poppins;
  color: #4d4f5c;
  position: relative;
  top: 4px;
}
.Toastify__toast-container p {
  font: normal normal normal 10px/16px Poppins;
  color: #645767;
  margin-bottom: 0px;
  position: relative;
  top: 4px;
}
.Toastify__toast {
  height: 48px !important;
  min-height: 48px;
  border-radius: 48px;
}
.Toastify__toast-body {
  padding: 0px;
}
.Toastify__toast-icon {
  width: 32px;
}
.Toastify__close-button--light {
  margin-top: 3px;
  margin-right: 3px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #4586eb;
  --toastify-color-success: #60c764;
  --toastify-color-warning: #f5d35c;
  --toastify-color-error: #de5e33;
  --toastify-color-transparent: hsla(0, 0%, 100%, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
}
@media (max-width: 767.98px) {
  .account-container main {
    overflow-x: hidden;
  }
}
@media (max-width: 768px) {
  .account-container .btn-toggle {
    display: flex;
  }
  .account-container .sidebar-toggle-btn {
    display: none;
  }

  .account-container .btn-toggle .rtl {
    margin-left: auto;
  }
}
/* Account End */

.settings_form_section .input_section span {
  color: red;
  position: relative;
  top: -23px;
  font: normal 12px/20px "Poppins";
}
.settings_form_section img.eye_icon {
  cursor: pointer;
}
@media (max-width: 991px) {
  .fixed-nav {
    margin-top: 173px;
  }

  /* About Us Start */

  .about_us_image {
    min-height: 350px;
  }

  .about_us_container .container-fluid,
  .about_us_detail_wrapper {
    padding: 0 0 43px 0;
  }
  /* About Us End */

  /* Agreement Start */
  .agreement_container {
    padding: 50px;
  }
  .agreement_container .heading {
    font: 600 42px/50px Poppins;
    margin-bottom: 25px;
  }
  /* Agreement End */

  .band_detail_nav_sticky .sticky-inner {
    top: 169px;
  }
}
@media (max-width: 967px) and (orientation: landscape) {
  .band_detail_nav_sticky .sticky-inner {
    top: 89px !important;
  }
}
@media (max-width: 768px) {
  /*.band_detail_nav {
		 display: none !important; 
	}*/
}
@media (max-width: 600px) {
  .fixed-nav {
    margin-top: 158px;
  }
  .band_detail_nav_item {
    margin: 0 20px;
    /* border-bottom: 5px solid #29a6ed; */
  }
  .band_detail_nav div {
    justify-content: flex-start;
  }
  /* About Us Start */
  .about_us_container {
    padding: 50px 15px;
  }
  .about_us_container .container-fluid {
    padding: 0;
  }
  .about_us_container_bg {
    transform: scaleX(4.5);
  }
  .about_us_detail_wrapper {
    padding-right: 0 !important;
  }
  .about_us_container .section_para span p {
    font: 14px/24px Poppins;
  }
  .section_mission .section_title,
  .section_vision .section_title {
    margin-bottom: 0.5rem;
  }
  .section_mission .section_para span p,
  .section_vision .section_para span p {
    margin-top: 50px;
  }
  .about_us_detail_wrapper .section_title,
  .section_mission .section_title,
  .section_vision .section_title {
    font: 500 16px/21px Poppins;
    letter-spacing: 1.12px;
    letter-spacing: 0;
  }
  .about_us_detail_wrapper .section_heading,
  .section_mission .section_heading,
  .section_vision .section_heading {
    font: 500 27px/39px Poppins;
  }
  /* About Us End */

  /* Agreement Start */
  .agreement_container {
    padding: 50px 30px;
  }
  .agreement_container .heading {
    font: 600 27px/39px Poppins;
    margin-bottom: 25px;
  }
  .agreement_container .sub-heading {
    font: 500 16px/21px Poppins;
    margin-bottom: 20px;
  }
  .agreement_content span p {
    font: 14px/24px Poppins;
  }
  /* Agreement End */

  .settings_page_para,
  .invite_page_para {
    font: normal 26px/36px "Poppins";
  }
  .settings_page_header {
    padding: 30px 35px;
    margin-bottom: 13px;
  }

  .password_section_login-settings .password_section_login {
    min-width: 80vw;
  }
  .settings_page_body {
    margin-bottom: 1rem;
  }
}

/* Contact-us Start */

.contact-us-bg {
  background-image: url("/assets/images/contact_us_bg.svg");
  filter: blur(18px);
  position: absolute;
  top: 120px;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(18px);
  z-index: -1;
}

.contact-us-wrapper {
  margin-top: 120px;
}
/* .contact-us-container {
  margin-top: 163px;
  margin-left: 64px;
} */
.contact-us-titleText {
  font: normal normal 600 72px/84px "General Sans";
  letter-spacing: 3px;
  color: #2f2233;
}
.contact-form-input .ant-picker .ant-picker-input input {
  border: none !important;
}
.contact-form-input
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-top: 7px;
}
.contact-img-bg {
  position: absolute;
  top: 0vh;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  height: 685px;
  border-radius: 24px;
  display: block;
  right: 0;
  /* background-image: linear-gradient(to bottom, #1d2a3682, #1d2a3691, #1d2a3699); */
  background-image: linear-gradient(to bottom, #1d2a3602, #18232e6b, #1a263241);

}
.contact-us-desc,
.contact-us-desc span p {
  flex: 1;
  text-align: left;
  font: normal normal normal 16px/30px "Poppins";
  letter-spacing: 0px;
  color: #645767;
}
.contact-us-ph {
  flex: 1;

}
.contact-us-phone {
  text-align: left;
  font: normal normal normal 16px/20px "Poppins";
  letter-spacing: 0px;
  color: #645767;
  min-width: 226px;
}
.contact-us-phoneNo {
  font: normal normal 500 26px/36px "Poppins";
  letter-spacing: 1.82px;
  color: #2f2233;
  cursor: pointer;
}

.contact-form-container {
  flex: 1;
  background: white;
  box-shadow: 0px 20px 40px #2f223324;
  border-radius: 12px;
  padding: 24px 32px;
}
.book-a-demo-container {
  flex: 1;
  background: white;
  box-shadow: 0px 20px 40px #2f223324;
  border-radius: 12px;
  padding: 24px 32px;
  overflow: hidden;
}
.contact-img {
  flex: 1;
}

.contact-form-heading {
  font: normal normal 500 28px/34px "Poppins";
  letter-spacing: 1.96px;
  color: #2f2233;
  text-transform: uppercase;
}
.contact-form-subheading {
  font: normal normal 500 18px/34px "Poppins";
  letter-spacing: 1.26px;
  color: #2f2233;
  text-transform: uppercase;
}
.book-demo-close-btn {
  position: absolute;
  right: 35px;
  top: 20px;
}
.contact-form-input label {
  font: normal normal normal 12px/16px "Poppins";
  letter-spacing: 0px;
  color: #786e82;
}
.contact-form-input input,
.contact-form-input .ant-picker,
.contact-form-input .ant-select {
  border: 1px solid #d7d1dd;
  border-radius: 24px;
  display: inherit;
}
.contact-form-input input::placeholder,
.contact-form-input .ant-picker .ant-picker-input input::placeholder,
.contact-form-input
  .ant-select
  .ant-select-selector
  .ant-select-selection-placeholder,
.contact-form-input textarea::placeholder {
  color: #786e82 !important;
  font: normal normal normal 14px/16px Poppins;
  letter-spacing: 0px;
}
.contact-form-input
  .ant-select
  .ant-select-selector
  span.ant-select-selection-placeholder {
  padding-top: 8px !important;
}
.contact-form-input
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0;

}
.contact-form-input .ant-picker:hover,
.contact-form-input .ant-picker-focused {
  border-color: #d7d1dd;
  box-shadow: none;
}
.contact-form-input textarea {
  border: 1px solid #d7d1dd;
  border-radius: 8px;
}

.contact-form-input
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.contact-form-input
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-top: 3px;
}
.contact-form-input
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 34px;
}
.contact-form-input .ant-picker-focused,
.contact-form-input .ant-select:focus {
  border-color: inherit;
}
.contact-form-input .ant-picker {
  line-height: 2.1;
}
.contact-form-input .form-control:focus {
  background: inherit;
  border-color: inherit;
  box-shadow: none;
}
.contact-form-input .ant-input:focus,
.contact-form-input .ant-input-focuses {
  border-color: black;
  box-shadow: none;
}
.contact-form-input .ant-input:hover {
  border-color: #d7d1dd;
}
.contact-form-input .ant-input:active {
  border: 1px solid black;
  border-radius: 8px;
}
.contact-us-img {
  width: 600px;
  height: 685px;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  object-fit: cover;
}

.cnt-img-sticky {
  position: sticky;
  top: 111px;
}
.cnt-us-img-text {
  text-align: left;
  font: normal normal 600 72px/80px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  left: 0;
  width: 100%;
  height: 421px;
  text-overflow: "ellipsis";
  overflow: hidden;
  display: block;
  padding: 0px 50px;
  /* display: flex;
align-items: center; */
  /* padding: 0 20%; */
}
.contact-us-submit-btn {
  background: #29a6ed 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  text-align: center;
  font: normal normal 500 18px/18px Poppins;
  letter-spacing: 0px;
  width: 115px;
  height: 45px;
  color: #ffffff;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.book-a-demo-btn {
  background: #29a6ed 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  text-align: center;
  font: normal normal 500 18px/18px Poppins;
  letter-spacing: 0px;
  width: 175px;
  height: 45px;
  color: #ffffff;
  display: inline-block;
  float: right;
}

.time-btn {
  background: #29a6ed 0% 0% no-repeat padding-box;
  border-radius: 24px;
  border: none;
  text-align: center;
  font: normal normal 500 18px/18px Poppins;
  letter-spacing: 0px;
  width: 105px;
  height: 45px;
  color: #ffffff;
  display: inline-block;
  
  
}

.cnt-us-FAQ {
  font: normal normal 500 38px/40px Poppins;
  letter-spacing: 0px;
  color: #2f2233;
  text-align: center;
}
.cnt-us-FAQ-btn {
  text-decoration: underline;
  font: normal normal 600 38px/40px Poppins;
  letter-spacing: 0px;
  color: #29a6ed;
  margin-left: 9px;
  cursor: pointer;
}
.bid-wrapper{
  height: 100%;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
}
.cnt-join-team-btn {
  width: 368px !important;
  height: 79px !important;
  font: normal normal 500 24px/32px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #29a6ed;
  border-radius: 48px;
  display: block;
  margin: 40px auto;
  border: none;
}

.contact-form-input .react-tel-input .selected-flag {
  padding: 0 0 0 12px;
}
.contact-form-input .react-tel-input .selected-flag:hover {
  background-color: transparent;
}
.contact-form-input .react-tel-input .form-control:focus {
  border-color: black;
}
.contact-form-input .react-tel-input .form-control {
  border: 1px solid #d7d1dd;
  border-radius: 24px;
  width: inherit;
  height: inherit;
}
.contact-form-input .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}

.cnt_success_img {
  margin-top: 176px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.bid-wrapper .cnt_success_heading {
  margin-top: 17.58px;
  text-align: center;
  font: normal normal 600 42px Poppins;
  letter-spacing: 0px;
  color: #2f2233;
  margin-left: auto;
  margin-right: auto;
}
.cnt_success_heading {
  margin-top: 17.58px;
  text-align: center;
  font: normal normal 600 28px/36px Poppins;
  letter-spacing: 0px;
  color: #2f2233;
  margin-left: auto;
  margin-right: auto;
}
.cnt_success_heading span p{
	font: normal normal 600 28px/36px Poppins,'SemiBold';
}
.cnt_success_desc span p {
	font: normal normal normal 16px/24px Poppins,'Regular';
}
.cnt_success_heading span p,
.cnt_success_desc span p {
  margin-bottom: 0;
}
.cnt_success_p{
  width: 100%;
  right: 4px;
  position: absolute;
}
.cnt_success_subheading {
  margin-top: 17.58px;
  text-align: center;
  font: normal normal 400 18px/18px Poppins;
  letter-spacing: 0px;
  color: #2f2233;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.cnt_success_subheading span p{
	font: normal normal 600 28px/36px Poppins,'SemiBold';
}
.bid-wrapper .cnt_success_desc span p {
	font: normal normal normal 20px Poppins,'Regular';
}
.cnt_success_desc span p {
	font: normal normal normal 16px/24px Poppins,'Regular';
}
.cnt_success_subheading span p,
.cnt_success_desc span p {
  margin-bottom: 0;
}
.bid-wrapper .cnt_success_desc {
  text-align: center;
  font: normal normal normal 20px Poppins;
  letter-spacing: 0px;
  color: #645767;
  margin-top: 17.58px;
}
.cnt_success_desc {
  text-align: center;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #645767;
  margin-top: 17.58px;
}

.success_data_pic {
  width: 110px;
  height: 110px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 5.42px;
  border-radius: 50%;
}

.cnt-success_name {
  text-align: center;
  font: normal normal 600 16px/24px Poppins;
  letter-spacing: 0px;
  color: #645767;
  margin-top: 16px;
}
.ant-spin-spinning {
  position: static !important;
}
.contact-us-submit-btn .ant-spin {
  background: #29a6ed 0% 0% no-repeat padding-box;
  border-radius: 24px;
  width: 115px;
  height: 45px;
}
.cnt-success_position {
  text-align: center;
  font: normal normal normal 16px/24px Poppins;
  letter-spacing: 0px;
  color: #645767;
}
.cnt-success_position span {
	font: normal normal 16px/24px Poppins;
}
.cnt-success_phone {
  text-align: center;
  font: normal normal 600 16px/24px Poppins;
  letter-spacing: 0px;
  color: #645767;
  margin-top: 14px;
}
.cnt-success_phone span{
	font: normal normal 600 16px/24px Poppins,'Regular';
}
.cnt-success_btn {
  border-radius: 24px;
  text-align: center;
  font: normal normal 600 18px/18px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #29a6ed;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 1rem;
  /* height: 45px; */
  border: none;
  margin-bottom: 24px;
}
.calendly-container { 
  height: 650px;
  width: 1200px;
  display: block;
  margin: auto;
  margin-top: -65px;
  }

@media (max-width: 1051px) {
  .calendly-container {
    width: 700px;
  }
}
@media (max-width: 768px) {
  .calendly-container {
    width: 450px;
  }
}
@media (max-width: 480px) {
  .calendly-container {
    width: 320px;
  }
}
@media (max-width: 321px) {
  .basic_info_fst_box_heading {
    font: normal normal normal 14px/28px Poppins !important;
    color: #fff;
}
}
.book-demo-right-btn {
  text-align: right
}
.book-demo-next-btn {
  min-width: 85px;
  height: 36px;
  padding: 0 14px;
  font: 500 14px/18px Poppins;
  background-color: #29a6ed;
  color: #fff;
  border: none;
  border-radius: 24px;
  margin-top: 20px;
}
@media (max-width: 1160px) {
  .contact_wrap {
    display: block !important;
  }
  .contact-form-container {
    /* margin-right: 7%; */
    margin-bottom: 3%;
  }
  .cnt-us-img-text {
    height: 481px;
    padding: 0 80px;
  }
  .contact-us-container{
    /* margin-right: 64px; */
    width: 100%;
    /* margin-left:30px; */
  }
}
@media (max-width: 830px) {
  .contact-top-text {
    display: block !important;
  }
  .contact-us-ph{
    display: block!important;
    margin-bottom: 20px!important;
    margin-top: 30px !important;
  }
  .contact-img{
    margin-right: 20px;
  }
  .contact-us-img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: top;
  }
  .contact-img-bg{
    width: 100%;
    height: 400px;
  }
  .cnt-us-img-text{
    font: normal normal 600 52px/60px Poppins;

    padding: 6px;
    width: 100%;
  }
  .cnt-us-img-text{
    height: auto;
    padding: 20px 40px;
    
  }
}

/* Contact-us End */

/* Event page Start */

.eventList-date {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 50px;
  margin-bottom: 18px;
  width: fit-content;
  min-height: 34px;
}

.eventList-date .ant-picker {
  border: transparent;
  background-color: transparent;
}

.eventList-date .ant-picker-focused {
  border-color: transparent;
  border-radius: 24px;
  border: transparent;
}

.eventList-date .ant-picker .ant-picker-input > input {
  color: white;
  font-size: 12px;
}

.eventList-date .ant-picker .ant-picker-input .ant-picker-suffix {
  display: none;
}
.eventList-date .ant-picker .ant-picker-input .ant-picker-clear {
  border-radius: 50%;
  right: 10%;
}
.ant-select-arrow {
  color: #909090 !important;
}
.eventList-date-label {
  color: #909090;
  align-self: center;
  font-size: 12px;
  font: normal normal normal 12px/17px Poppins;
  letter-spacing: 0px;
}
.dropDownIcon {
  position: relative;
  z-index: 3;
  cursor: pointer;
  right: 5%;
  width: 10.48px;
}

.eventList-date .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}

.eventList-date
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-top: 2.3px;
  color: white;
}

.eventsList-dropdown {
  min-width: 100px !important;
  /* background: rgba(255, 255, 255, 0.288) !important; */
  box-shadow: 0px 15px 30px #0000001a !important;
  border-radius: 5px !important;
}
.eventList-date
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item {
  padding-bottom: 0px !important;
}
/*
.eventsList-dropdown .ant-select-item {
	border-radius: 20px;
	background-color: inherit !important;
}
.eventsList-dropdown  .ant-select-item-option-selected .ant-select-item-option-content{
	color: white;
}
.eventsList-dropdown  .ant-select-item:hover{
background-color: inherit !important;
color: rgb(25, 0, 255);
}
.eventsList-dropdown  .ant-select-item-option-selected .ant-select-item-option-content:hover{
	color:white
}
.eventsList-dropdown  .ant-select-item-option-content:hover{
	color: rgb(27, 1, 255);
} */

.list-events-container {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 15px;
  backdrop-filter: blur(33px);
  width: 100%;
  height: fit-content;
  padding: 43px 32px;
}

.list-event {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 32px;
  padding: 32px;
}

.list-event-img {
  width: 87.42px;
  height: 87.42px;
  object-fit: cover;
  border-radius: 9px;
}
.list-event-desc {
  font: normal 10px "Poppins";
  color: white;
  opacity: 0.52;
}

.list-event-bookedDate {
  font: normal 14px "Poppins";
  color: white;
  text-align: right;
}
.list-event-band {
  font: normal 14px "Poppins";
  color: white;
  margin-top: 6.26px;
}
.list-event-time {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 5px;
  padding: 5.5px 13px;
  font: normal 12px "Poppins";
  color: white;
  display: block;
  margin-right: auto;
  margin-bottom: 10px;
  text-align: center;
  width: fit-content;
}
.list-event-venue {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 5px;
  padding: 5.5px 13px;
  font: normal 12px "Poppins";
  color: white;
  display: block;
  margin-right: auto;
  width: fit-content;
  text-align: center;
}

.list-event-lastContainer {
  margin-top: 11.74px;
}

.list-event-btn {
  background: #29a6ed 0% 0% no-repeat padding-box;
  border-radius: 8px;
  width: 71px;
  height: 33px;
  padding-top: 8px;
  display: block;
  margin-left: auto;
}
.list-event-btn button {
  background-color: transparent;
  border: none;
  margin: auto;
  display: block;
  text-align: left;
  font: normal normal 500 12px/17px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
}

.ep-event-list-img {
  border-radius: 50% !important;
}

.list-event:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 580px) {
  .list-event {
    display: block !important;
    position: relative;
  }
  .list-event-container {
    margin-top: 20px;
  }
  .contact-us-wrapper {
    margin-top: 180px;
  }
  .contact-us-titleText {
    font: normal normal 600 46px/84px "General Sans";
    letter-spacing: 3px;
    color: #2f2233;
    text-align: center;
  }
}

.eventList-topContainer .search_box {
  display: flex;
  background-color: transparent;
  padding: 0px;
  margin-left: 20px;
}

.eventList-topContainer .search_box .form-control {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 50px;
  border: none;
  font: normal 12px "Poppins";
  margin-bottom: 18px;
  margin-right: 12px;
  color: white;
}
/* Event page End */

/* Event detail page Start */
.event-detail-heading {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 28px/34px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 35.96px;
}
.event-detail-subheading {
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: normal normal normal 23px/30px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 35.96px;
}
.event-detail-sub-heading {
  letter-spacing: var(--unnamed-character-spacing-0);
  float: center;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px !important;
  margin-top: 29.17px;
  margin-left: 20px;
}
.docuSign_response {
  font: normal 20px/24px "Poppins";
  margin-top: auto !important;
  margin-bottom: auto;
}
.event-detail-nav {
  background: rgba(255, 255, 255, 0.06);
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 50px;
  width: fit-content ;
  margin-top: 14.17px;
  display: inline-block;
}

.event-detail-nav .custom_nav .nav-item .nav-link {
  color: rgba(255, 255, 255, 0.45);
  border-radius: 50px;
  font: normal normal normal 14px Poppins;
}
.event-detail-nav .custom_nav .nav-item .active.nav-link {
  background-color: #29a6ed;
  color: white;
  border-radius: 27px;
  border: none;
}
.event_tab_list .eventList-date {
  display: flex;
  justify-content: center;
  align-self: center;
}
.event_tab_list
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  align-items: end;
}

/* Event detail page End */

/* Event Planner Dashboard Start */

.ep_basic_img {
  width: 56px !important;
  height: 56px !important;
  border-radius: 50% !important;
  border: 2px solid #4c4653;
}

.ep-fade-effect {
  margin-bottom: 18px !important;
  margin-top: 10px;
  display: block;
  border: none;
  color: white;
  height: 1px;
  background: black;
  background: -webkit-gradient(
    radial,
    0% 0%,
    0,
    50% 50%,
    120,
    from(rgb(250, 124, 65)),
    to(#000)
  );
}

.ep-event-desc {
  font: normal normal 500 14px/23px Poppins;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.ep-event-bandName {
  font: normal normal 500 13px/20px Poppins;
  color: #ffffff;
  text-transform: capitalize;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.basic_info_fst_box_heading {
  font: normal normal normal 16px/28px Poppins;
    color: #fff;
}
.ep-event-dateVenue {
  font: normal normal normal 12px/20px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.34;
  margin-top: 0px;
  margin-right: 5px;
}
.upcoming_img {
    height: 115px;
    border-radius: 10px;
    width: 200px;
    object-fit: cover;
}
.basic_info_time {
  font: normal normal normal 12px/18px Poppins;
    color: #fff;
    background: #424046;
    padding: 3px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: table;
    margin-right: 10px;
}
.ep-event-container {
  margin-left: 29px;
}
.modal-open .pac-container{
  z-index: 9999;
}
@media (max-width: 1000px) {

  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 90px;
    right: 115px;
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
}
@media (max-width: 1441px){
  .upcoming_img {
    height: 115px;
    border-radius: 10px;
    width: 140px;
    object-fit: cover;
}
}
@media (max-width: 1210px) {
  .ep-event-container {
    margin-top: 20px !important;
  }
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 160px;
    right: 200px;
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
}
/* Event Planner Dashboard End */
@media (max-width: 1024px) {
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 90px;
    right: 140px;
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
  .basic_info_fst_box_heading {
    font: normal normal normal 15px/28px Poppins;
      color: #fff;
  }
}
/*  */
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 88%);
  z-index: 1000;
}
.loader_imgs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: auto;
  flex-direction: column;
}
.loader_imgs h1 {
  font: normal normal 600 26px/42px "Poppins";
  color: #ffffff;
  margin-top: 30px;
}
.loader_imgs h1 span {
  font: normal normal 600 26px/42px Poppins;
  color: #29A6ED;
}

.loader_imgs1 {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: auto;
  position: absolute;
  top: 20%;
}
.loader_imgs1 h1 {
  font: normal normal 600 26px/42px "Poppins";
  color: #ffffff;
  margin-top: 30px;
}
.loader_imgs1 h1 span {
  font: normal normal 600 26px/42px Poppins;
  color: #29A6ED;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

.loder_slider .ant-carousel .slick-slide img {
  margin: auto;
}
.loder_slider .ant-carousel .slick-dots-bottom {
  display: none !important;
}

.contract-success-modal .ant-modal-body{
  background-image: url("/assets/images/contract_success.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-radius: 15px;
}
h4.contract-success-title{
  font: normal normal bold 28px/40px Poppins;
letter-spacing: 0px;
color: #1A2632;
text-align: center;
padding: 30px 40px 10px 40px;
margin-bottom: 0;
}
p.contract-success-pera{
  font: normal normal normal 12px/23px Poppins;
  text-align: center;
  padding:0px 40px 10px 40px;
}
.bid-contract-success{
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.bid-contract-success p{
  font: normal normal normal 12px / 23px Poppins;
  margin-right: 7px;
}
.bid_step_modal_div .ant-modal-content{
  border-radius: 15px;
}
.bid_step_modal_div .ant-modal-close-icon{
  display: none;
}
.contract-success-btn{
  font: normal normal normal 12px/23px Poppins;
  background:#29A6ED;
  color:#fff;
  padding:5px 10px;
  text-align: center;
  border-radius: 5px;
  border:none;
  
}
.evt-status-box{
  width: 100%;
  padding: 5px 10px;
  background: #e4494966;
  border:1px solid #E44949;
  border-radius: 10px;
  /* opacity: 0;
  animation: fadeOut 0.5s ease-out 5s forwards; */
  transition: all 250ms linear 2s;
}
.evt-detail-status{
  width: 70%;
  color: #fff;
  font: normal normal normal 14px/18px Poppins;
  float: left;
  margin-bottom: 0px;
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.bid_contract .ant-modal-content{
  border-radius: 10px;
}
.bid_contract .ant-modal-body{
  background: #2F2233;
  border-radius: 7px;
}
.contract-success-modal .ant-modal-content{
  border-radius: 15px;
}
.add_user_modal_content .ant-modal-content{
  border-radius: 15px;
}
.notificationListDropdownContainer {
  max-height: 80vh;
  width: 30vw;
  border-radius: 11px;
  margin-top: 12px;
  padding: 24px;
  overflow-y: scroll;
  background-color: white;
}
@media (max-width: 768px) {
  .loder_slider .ant-carousel .slick-slide img {
    width: 180px;
  }
  .loader_imgs1 {
    top: 30%;
  }
  .ant-tooltip-inner {
    display: none;
   
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .contact-us-phone {
    text-align: center;
    font: normal normal normal 16px/20px "Poppins";
    letter-spacing: 0px;
    color: #645767;
    min-width: 226px;
  
  }
  .contact-us-phoneNo {
    font: normal normal 500 26px/36px "Poppins";
    letter-spacing: 1.82px;
    color: #2f2233;
    cursor: pointer;
 position: relative;
 top: 0px;
 left: 240px !important;
  }
  .loader_imgs1 h1,
  .loader_imgs1 h1 span {
    font: normal normal 600 20px/42px "Poppins";
  }
  .list-event-container{
    margin-left: 10px!important;
  }
  .pd-lr-15{
    padding: 0px 15px;
  }
  .notificationListDropdownContainer {
    max-height: 80vh;
    width: 400px !important;
    border-radius: 11px;
    margin-top: 12px;
    padding: 24px;
    overflow-y: scroll;
    background-color: white;
    margin-left: 10px;
  }
}
.notificationListDropdownContainer  ul {
  padding-left: 0rem !important;
}
@media (max-width: 580px){
  .list-event-bookedDate{
    position:absolute;
    top:30px;
    right: 10px;
    width: 50%;
  }
  .ant-tooltip-inner {
    display: none;
   
  }
  .ant-tooltip-arrow {
    display: none;
  }
}

@media (max-width: 480px){
  h4.contract-success-title{
    font: normal normal bold 20px/30px Poppins;
    padding: 30px 15px 10px 15px;
  }
  .upcoming_img {
    /* height: auto !important; */
    border-radius: 10px;
    object-fit: cover;
}
.move_down_shareIcon{
  position: relative;
  top: 100px;
  right: 30px;
}
  p.contract-success-pera{
    font: normal normal normal 10px/18px Poppins;
  
    padding:0px 15px 10px 15px;
  }
  .ant-tooltip-inner {
    display: none;
   
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .genres-checkbox-option{
    flex-direction: column;
  }
  .cnt-us-img-text{
    font: normal normal 600 43px/50px Poppins;
    padding: 20px;
  }
  .contact-us-phone {
    text-align: center;
    font: normal normal normal 16px/20px "Poppins";
    letter-spacing: 0px;
    color: #645767;
    min-width: 226px;
  
  }
  .contact-us-phoneNo {
    font: normal normal 500 26px/36px "Poppins";
    letter-spacing: 1.82px;
    color: #2f2233;
    cursor: pointer;
 position: relative;
 top: 0px;
 left: 70px !important;
  }
  .cnt-us-FAQ {
    font: normal normal 500 23px/30px Poppins;
    letter-spacing: 0px;
    color: #2f2233;
    position: relative; 
top: 0px;

text-align: center;
   
  }
  .cnt-us-FAQ-btn {
    text-decoration: underline;
    font: normal normal 600 28px/34px Poppins;
    letter-spacing: 0px;
    color: #29a6ed;
    display: block;
    cursor: pointer;

  }
}
@media (max-width: 740px)  {

  .notificationListDropdownContainer {
    max-height: 80vh;
    width: 400px !important;
    border-radius: 11px;
    margin-top: 12px;
   
    overflow-y: scroll;
    background-color: white;
    margin-left: 12px;
  }
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 90px;
    right: 115px;
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
}
@media (max-width: 540px)  {

  .notificationListDropdownContainer {
    max-height: 80vh;
    width:400px !important;
    border-radius: 11px;
    margin-top: 12px;
   
    overflow-y: scroll;
    background-color: white;
    margin-left: 12px;
  }
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 90px;
    right: 115px;
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
}
@media (max-width: 390px)  {

  .notificationListDropdownContainer {
    max-height: 80vh;
    width: 363px  !important;
    border-radius: 11px;
    margin-top: 12px;
    overflow-y: scroll;
    background-color: white;
    margin-left: 12px;
  }
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 100px;
    right: 25px;
    font: normal normal normal 16px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
}
@media (max-width: 376px) {
  .contact-us-phone {
    text-align: center;
    font: normal normal normal 16px/20px "Poppins";
    letter-spacing: 0px;
    color: #645767;
    min-width: 226px;
  
  }
  .ant-tooltip-inner {
    display: none;
   
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .contact-us-phoneNo {
    font: normal normal 500 26px/36px "Poppins";
    letter-spacing: 1.82px;
    color: #2f2233;
    cursor: pointer;
 position: relative;
 top: 0px;
 left: 50px !important;
  }
  .mb_disply_non {
    display: none;
  }
  .header_left_logo img{
    width:120px;
  }
  .cnt-join-team-btn {
    width: 100% !important;
    height: 59px !important;
    font: normal normal 500 18px/26px "Poppins";
  }
  .cnt-us-FAQ {
    font: normal normal 500 23px/30px Poppins;
    letter-spacing: 0px;
    color: #2f2233;
    position: relative; 
top: 0px;

text-align: center;
   
  }
  .cnt-us-FAQ-btn {
    text-decoration: underline;
    font: normal normal 600 28px/34px Poppins;
    letter-spacing: 0px;
    color: #29a6ed;
    margin-left: 9px;
    cursor: pointer;
position: relative; 

  }
}
@media (max-width: 376px) {
  .notificationListDropdownContainer {
    max-height: 80vh;
    width: 346px  !important;
    border-radius: 11px;
    margin-top: 12px;
    padding: 24px;
    overflow-y: scroll;
    background-color: white;
    margin-left: 13px;
  }
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 100px;
    right: 25px;
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
}
@media (max-width: 321px) {
  .event-detail-sub-heading {
    letter-spacing: var(--unnamed-character-spacing-0);
    position: absolute;
    top: 92px;
    right: 27px;
    font: normal normal normal 14px/20px Poppins;
    letter-spacing: 0px;
    color: #ffffff;
    margin-bottom: 0px !important;
  
  }
  .notificationListDropdownContainer {
    max-height: 80vh;
    width: 300px !important;
    border-radius: 11px;
    margin-top: 12px;
    padding: 24px;
    overflow-y: scroll;
    background-color: white;
    margin-left: 10px;
  }
  .cnt-us-FAQ {
    font: normal normal 500 23px/30px Poppins;
    letter-spacing: 0px;
    color: #2f2233;
    position: relative; 
top: 0px;

text-align: center;
   
  }
  .ant-tooltip-inner {
    display: none;
   
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .cnt-us-FAQ-btn {
    text-decoration: underline;
    font: normal normal 600 28px/30px Poppins;
    letter-spacing: 0px;
    color: #29a6ed;
    cursor: pointer;
position: relative; 

  }
  .contact-us-phoneNo {
    font: normal normal 500 26px/36px "Poppins";
    letter-spacing: 1.82px;
    color: #2f2233;
    cursor: pointer;
 position: relative;
 top: 0px;
 left: 30px !important;
  }
}

/* Insta Feed Start */
.feeds_container {
  padding: 54px 0 72px;
  overflow: hidden;
  position: relative;
  background-color: #f9f9f9;
}
.feeds_container > *:not(.feed_bg) {
  position: relative;
  z-index: 3;
}
.feed_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #34383e;
  border-radius: 0 0 50% 50% / 0 0 100% 100%;
  transform: scaleX(4);
}
.feeds_heading {
  font: 500 28px/34px "Poppins";
  color: #ffffff;
  margin-bottom: 120px;
  letter-spacing: 1.96px;
  text-align: center;
}
.feeds_btn {
  border: 1px solid #29a6ed !important;
  border-radius: 41px !important;
  min-width: 143px;
  height: 54px !important;
  font: 500 16px/20px "Poppins";
  color: #29a6ed !important;
  background-color: transparent !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin: 16px auto 0;
}
.feeds_btn a,
.feeds_btn span {
  font: 500 16px/20px "Poppins";
  color: #29a6ed !important;
}
.feeds_container ._2R-kh {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -16px;
}
.feeds_container ._wPDyp {
  flex-basis: calc(100% / 5 - 32px);
  margin: 16px;
  opacity: 0.5;
  transition: all 0.5s linear;
}
.feeds_container ._wPDyp:hover {
  opacity: 1;
}
.feeds_container ._wPDyp:first-child,
.feeds_container ._wPDyp:nth-child(5),
.feeds_container ._wPDyp:nth-child(6),
.feeds_container ._wPDyp:nth-child(10),
.feeds_container ._wPDyp:nth-child(11),
.feeds_container ._wPDyp:nth-child(15) {
  top: -64px;
  position: relative;
}
.feeds_container ._wPDyp:nth-child(2),
.feeds_container ._wPDyp:nth-child(4),
.feeds_container ._wPDyp:nth-child(7),
.feeds_container ._wPDyp:nth-child(9),
.feeds_container ._wPDyp:nth-child(12),
.feeds_container ._wPDyp:nth-child(14) {
  top: -32px;
  position: relative;
}
.feeds_container ._vzTHL {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.feeds_container ._3xnQP {
  opacity: 0 !important;
}
.home_testimonial_container > section{
  background-color: #34383e !important;
}
.home_testimonial_container > section::before {
  background-color: #1f2330 !important;
}
.insta_section_btn {
  font: 600 16px/30px 'Poppins';
  color: #ffffff;
  background-color: #29a6ed;
  border-radius: 48px;
  min-width: 273px;
  height: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 0 35px;
  /* text-transform: uppercase; */
}

@media (max-width: 767px) {
  .feeds_heading {
    font: 500 17px/24px "Poppins";
    letter-spacing: 1.19px;
    margin-bottom: 38px;
  }

  .feeds_container ._wPDyp {
    flex-basis: calc(100% / 5 - 16px);
    margin: 8px;
  }
  .feeds_container ._vzTHL {
    border-radius: 12px;
  }

  .insta_section_btn {
    font: 500 16px/20px "Poppins";
    min-width: 181px;
    height: 51px;
    padding: 0 22px;
  }
  .insta_section_btn img {
    width: 17px;
  }
}
@media (max-width: 600px) {
  .feeds_container ._wPDyp {
    flex-basis: calc(100% / 3 - 16px);
    margin: 8px;
    opacity: 1;
  }
  .ant-tooltip-inner {
    display: none;
   
  }
  .ant-tooltip-arrow {
    display: none;
  }
  .feeds_container ._vzTHL {
    border-radius: 12px;
  }
  .feeds_container ._wPDyp:first-child,
  .feeds_container ._wPDyp:nth-child(5),
  .feeds_container ._wPDyp:nth-child(6),
  .feeds_container ._wPDyp:nth-child(10),
  .feeds_container ._wPDyp:nth-child(11),
  .feeds_container ._wPDyp:nth-child(15) {
    top: 0;
  }
  .feeds_container ._wPDyp:nth-child(2),
  .feeds_container ._wPDyp:nth-child(4),
  .feeds_container ._wPDyp:nth-child(7),
  .feeds_container ._wPDyp:nth-child(9),
  .feeds_container ._wPDyp:nth-child(12),
  .feeds_container ._wPDyp:nth-child(14) {
    top: 0;
  }
  .feeds_container ._wPDyp:first-child,
  .feeds_container ._wPDyp:nth-child(3),
  .feeds_container ._wPDyp:nth-child(4),
  .feeds_container ._wPDyp:nth-child(6),
  .feeds_container ._wPDyp:nth-child(7),
  .feeds_container ._wPDyp:nth-child(9),
  .feeds_container ._wPDyp:nth-child(10),
  .feeds_container ._wPDyp:nth-child(12),
  .feeds_container ._wPDyp:nth-child(13),
  .feeds_container ._wPDyp:nth-child(15) {
    top: -16px;
  }
  .feed_bg {
    transform: scaleX(5);
  }
}
.ant-select-dropdown {
  border-radius: 14px !important;
}
.invite_primary_btn_invite-new-button{
  font: normal 14px/18px "Poppins" !important;
  color: #ffffff !important;
  border-radius: 7px !important;
  padding: 0 24px !important;
  height: 52px !important;
  background-color: #29a6ed !important;
  border: none !important;
  box-shadow: none !important;
  margin-left:20px;
  width:200px;
}
@media (max-width:466px){
  .invite_primary_btn_invite-new-button{
    font: normal 14px/18px "Poppins" !important;
    color: #ffffff !important;
    border-radius: 7px !important;
    padding: 0 24px !important;
    height: 52px !important;
    background-color: #29a6ed !important;
    border: none !important;
    box-shadow: none !important;
    margin-left:0;
    margin-top: 20px;
    width:200px;
  }
}

/* Plaid modal */
.ant-modal-confirm.ant-modal-confirm-confirm.remove_plaid_modal{
  width: 516px !important;
  top: 25%;
}

.remove_plaid_modal .ant-modal-content{
  border-radius: 24px;
}

.remove_plaid_modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .anticon-exclamation-circle{
  color:red
}

.remove_plaid_modal .ant-modal-content .ant-modal-confirm-btns button{
  border-radius: 12px;
  border-color: red;
  color:red
}
.remove_plaid_modal .ant-modal-content .ant-modal-confirm-btns button.ant-btn-primary{
  margin-left: 20px;
  background: #ff0000da;
  border: none;
  color:white
}

/* Insta Feed End */

@media screen and (min-width: 320px) and (max-width:890px) and (orientation:portrait){
  body {
    height: 100vh !important;
    transform: rotate(0deg) !important;
    -webkit-transform: none !important;
  }
  .main_page {
    display: visible;
  }
  .rotate_screen {
    display: none;
  }
}
@media   screen and (min-width: 320px) and (max-width:890px) and (orientation: landscape){
.main_page {
  display: none;
}
.rotate_screen {
  margin-right: auto;
  border-radius: 0px;
  display: visible;
  font: normal normal 600 28px/30px Poppins;
  color: #29a6ed;
  background: url(../public/assets/images/rotate.png);
  height: 100vh;
  width: 100vw;
  background-size: cover;
  object-fit: fill;
  object-position: center;
  margin-left: auto;
  margin-bottom: auto;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
}
}
/* @media screen and (orientation:landscape) and (max-device-width: 900px) {
  html{
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  width: 420px;
  position: fixed;
  top: 100%;
  left: 0
  }
  } */

  .note{
    color: rgb(230, 46, 46);
    font: normal 12px "Poppins";
  }

  .validating-link-span{
    color: #29a6ed;
    font: 500 24px "Poppins"; 
  }

  .link_validation_error_icon{
    font-size: 100px;
    color: rgb(230, 46, 46);
    background-color: #fff;
    border-radius: 50%;
  }

  .success_icon{
    font-size: 100px;
    color: #4ad172;
    background-color: #fff;
    border-radius: 50%;
    animation: comeFromRight .8s ease-in-out;;
  }
  
  @keyframes comeFromRight {
    0%{
      /* transform: translateX(100%); */
      scale: 0.3;
      opacity: 0;
      background: green;
    }
    50%{
      opacity: .5;
    }
    90%{
      scale:1.2;
    }
    100%{
      scale:1;
      background: white;
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .grecaptcha-badge { 
    bottom:80px !important; 
}
.ant-message{
  bottom: 64px!important;
}

.auto-refresh-modal-wrap{
  
}